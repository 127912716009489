
import { Vue, Component } from 'vue-property-decorator'
import { toThousands } from '@/utils/formatData'
import { mathAdd, mathDiv, mathMul, mathSub } from '@/utils/math'
import { monthDayFormat } from '@/utils/formatDate'
import { InsectList, ProjectList, ScatterCharData } from '@/types/insectMonitor'

import Echarts from 'echarts'
@Component({
  name: 'insectAnalyze',
  filters: {
    toThousands
  }
})
export default class extends Vue {
  private searchInfo = {
    projectId: ''
  }

  private dayDate = ''
  private monthDate = ''
  private loading = false
  private pageLoading = false
  private tableData: Array<InsectList> = []
  private showPage = false
  private showAnalyse = false
  private insectDataSortList: any = []
  private insectDataIndex = 0
  private timer2Min: any = null
  private timer30Second: any = null
  private currentInsectName = ''
  private isFirst = true

  private projectList: Array<ProjectList> = []

  private insectList: Array<InsectList> = []
  private scatterEcharts: any = null // 四象限图
  private lineEcharts: any = null // 折线
  private pieEcharts: any = null // 饼图
  private pieChartColor = [
    '#47A2FF',
    '#1AD1B0',
    '#59CB74',
    '#FBD44C',
    '#F3657C',
    '#9760E5'
  ]

  private pieChartData: Array<InsectList> = [] // 饼图数据
  private scatterCharData: ScatterCharData = {} // 四象限数据
  private lineChartNumber: Array<number> = [] // 折线数据
  private lineChartDate: Array<string> = [] // 折线x轴日期
  private lineChartPercent: Array<string> = [] // 折线y轴增长率
  // 四象限点对应的临时数据存放
  private tempData1: Array<any> = []
  private tempData2: Array<any> = []
  private tempData3: Array<any> = []
  private tempData4: Array<any> = []

  created () {
    this.getProject()
    clearInterval(this.timer30Second)
    clearInterval(this.timer2Min)
  }

  mounted () {
    window.addEventListener('resize', this.changeResize)
  }

  changeResize () {
    if (this.scatterEcharts) {
      this.scatterEcharts.resize()
    }
    if (this.lineEcharts) {
      this.lineEcharts.resize()
    }
    if (this.pieEcharts) {
      this.pieEcharts.resize()
    }
  }

  //  搜索项目
  searchData () {
    if (!this.searchInfo.projectId) {
      this.$message.warning('请输入项目名')
      return
    }
    this.searchResult()
  }

  getProject () {
    this.$axios
      .get(this.$apis.insectMonitor.selectDeviceProjectList, {
        deviceTypeId: '1017'
      })
      .then((res) => {
        if (res && res.list && res.list.length >= 0) {
          this.projectList = res.list
          this.searchInfo.projectId = res.list[0].projectId
          this.searchResult()
        } else {
          this.showPage = true
        }
      })
  }

  // 搜索项目
  searchResult () {
    this.get7DayData(this.searchInfo.projectId) // 获取7天数据
    this.get30DayNumber(this.searchInfo.projectId) // 获取30天数量分析数据
    this.get30Daygrowth(this.searchInfo.projectId) // 获取30天增长分析数据
  }

  get7DayData (id: string) {
    this.$axios
      .get(this.$apis.insectMonitor.selectDaySevenNew, {
        projectId: id
      })
      .then((res) => {
        if (res && res.dataList) {
          this.insectList = res.dataList
          this.dayDate = res.date
        }
      })
  }

  // 饼图表格数据
  get30DayNumber (id: string | number) {
    this.$axios
      .get(this.$apis.insectMonitor.selectAnalysis, { projectId: id })
      .then((res) => {
        if (res) {
          this.tableData = res
          this.pieChartData = res
          this.$nextTick(() => {
            this.getEchartsPie() // 饼图
          })
        }
      })
  }

  // 2分钟的定时器
  interval2Min () {
    clearInterval(this.timer30Second)
    clearInterval(this.timer2Min)
    let time = 0
    this.timer2Min = setInterval(() => {
      time += 1
      if (time >= 120) {
        this.interval30Second()
        clearInterval(this.timer2Min)
        time = 0
      }
    }, 1000)
  }

  // 30秒
  interval30Second () {
    clearInterval(this.timer30Second)
    clearInterval(this.timer2Min)
    let time = 0
    this.timer30Second = setInterval(() => {
      time += 1
      if (time >= 30) {
        if (
          this.insectDataIndex >=
          this.scatterCharData.insect_data.length - 1
        ) {
          this.insectDataIndex = 0
        } else {
          this.insectDataIndex += 1
        }
        this.uploadLineEchart()

        time = 0
      }
    }, 1000)
  }

  // 更新折线图 四象限
  uploadLineEchart () {
    this.lineChartPercent = this.insectDataSortList[
      this.insectDataIndex
    ].insectIncreaseList
    this.lineChartNumber = this.insectDataSortList[
      this.insectDataIndex
    ].insectNumberList
    this.currentInsectName = this.insectDataSortList[
      this.insectDataIndex
    ].insectName
    this.getEchartsLine()
    this.getEchartsAcatter()
    this.interval2Min()
  }

  // 获取30天增长分析数据
  get30Daygrowth (id: string | number) {
    this.$axios
      .get(this.$apis.insectMonitor.selectFourQuadrants, { projectId: id })
      .then((res) => {
        if (res) {
          if ((res.insect_data && res.insect_data.length > 0) && (res.date_list && res.date_list.length > 0)) {
            this.showAnalyse = false
          } else {
            this.showAnalyse = true
            return false
          }

          this.scatterCharData = res

          if (res.date_list && res.date_list.length > 0) {
            const startTime = monthDayFormat(
              new Date(res.date_list[0]).getTime()
            )
            const endTime = monthDayFormat(
              new Date(res.date_list[res.date_list.length - 1]).getTime()
            )
            this.monthDate = startTime + '-' + endTime
          }

          this.lineChartDate = res.date_list ? res.date_list : []
          if (res.insect_data && res.insect_data.length > 0) {
            this.insectDataSortList = this.insectSort(res.insect_data)
          }

          this.$nextTick(() => {
            this.uploadLineEchart()

          //   this.getEchartsAcatter() // 四象限
          //   this.getEchartsLine() // 折线图
          })
        } else {
          this.showAnalyse = true
        }
      })
  }

  // 获取当前四象限点的虫害名字
  getCurInsectName (params: any) {
    let name = ''
    if (params.seriesName === '初始期') {
      name = this.getInsectName(this.tempData1, params.dataIndex)
    } else if (params.seriesName === '爆发期') {
      name = this.getInsectName(this.tempData2, params.dataIndex)
    } else if (params.seriesName === '消亡期') {
      name = this.getInsectName(this.tempData3, params.dataIndex)
    } else if (params.seriesName === '危害期') {
      name = this.getInsectName(this.tempData4, params.dataIndex)
    }
    return name
  }

  // 四象限
  getEchartsAcatter () {
    this.tempData1 = []
    this.tempData2 = []
    this.tempData3 = []
    this.tempData4 = []
    const coordinateArr: Array<any> = []
    const tempData: Array<any> = []

    const xArr: Array<number> = []
    const yArr: Array<number> = []
    // 初始期
    const oneData: Array<any> = []
    // 爆发期
    const twoData: Array<any> = []
    // 消亡期
    const threeData: Array<any> = []
    // 危害期
    const fourData: Array<any> = []
    if (
      this.scatterCharData &&
      this.scatterCharData.insect_data &&
      this.scatterCharData.insect_data.length > 0
    ) {
      this.scatterCharData.insect_data.forEach((item: any) => {
        coordinateArr.push([
          Number(item.insectNumbers),
          Number(item.increasePercent)
        ])
        tempData.push({
          insectName: item.insectName,
          insectNumberList: item.insectNumberList,
          insectIncreaseList: item.insectIncreaseList,
          dateList: this.scatterCharData.date_list
        })
        xArr.push(Number(item.insectNumbers))
        yArr.push(Number(item.increasePercent))
      })
      const xMargin = mathDiv(Math.max(...xArr), 10)
      const yMargin = mathDiv(Math.max(...yArr), 10)
      const xMin = Math.min(...xArr) - xMargin
      const xMax = Math.max(...xArr) + xMargin
      let yMin = Math.min(...yArr) - yMargin
      let yMax = Math.max(...yArr) + yMargin
      if (yMin === yMax) {
        yMin = yMin - 2
        yMax = yMax + 2
      }
      const xCenter = this.scatterCharData.coordinate[0]
      const yCenter = this.scatterCharData.coordinate[1]

      if (coordinateArr.length > 0) {
        coordinateArr.forEach((item: any, index: number) => {
          // 初始期 x <= x中心点 &&  y >= y中心点  包含中心点位置
          if (item[0] <= xCenter && item[1] >= yCenter) {
            oneData.push([item[0], item[1]])
            this.tempData1.push({ name: '初始期', data: tempData[index] })
          } else if (
            (item[0] >= xCenter && item[1] > yCenter) ||
            (item[0] > xCenter && item[1] >= yCenter)
          ) {
            // 爆发期 x >= x中心点 && y > 中心点    || x  > x中心点  && y >=  y中心点
            twoData.push([item[0], item[1]])
            this.tempData2.push({ name: '爆发期', data: tempData[index] })
          } else if (
            (item[0] <= xCenter && item[1] < yCenter) ||
            (item[0] < xCenter && item[1] <= yCenter)
          ) {
            // 消亡期  x <= x中心点  && y < y中心点  || x < x中心点  && y <=  y中心点
            threeData.push([item[0], item[1]])
            this.tempData3.push({ name: '消亡期', data: tempData[index] })
          } else if (
            (item[0] >= xCenter && item[1] < yCenter) ||
            (item[0] > xCenter && item[1] <= yCenter)
          ) {
            // 危害期 x >= x中心点 && y < y中心点  || x > x中心点  && y <= y中心点

            fourData.push([item[0], item[1]])
            this.tempData4.push({ name: '危害期', data: tempData[index] })
          }
        })
      }
      this.scatterEcharts = Echarts.init(this.$refs.scatterEcharts as any)
      this.scatterEcharts.off('click')
      const option = {
        grid: {
          top: '0%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          // containLabel: true
          show: true,
          backgroundColor: '#F6F7FB',
          borderWidth: 0
        },
        tooltip: {
          showDelay: 0,
          formatter: (params: any) => {
            const name = this.getCurInsectName(params)

            if (params.value.length > 1) {
              const num = mathMul(params.value[1].toString(), 100)
              return `${name}:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>数量:${params.value[0]}<br/>增长率:${num}%`
            }
            return false
          }
        },
        legend: [
          {
            orient: 'horizontal',
            left: 10,
            top: 10,
            align: 'left',
            data: ['初始期'],
            textStyle: {
              fontSize: 14,
              color: '#2487FA'
            },
            itemStyle: {
              color: 'rgba(24,144,255,0.3)',
              borderColor: '#1890FF',
              borderWidth: 1,
              borderType: 'solid'
            },
            backgroundColor: 'rgba(232,234,240,.5)',
            padding: [13, 16],
            borderRadius: 4
          },
          {
            orient: 'horizontal',
            right: 10,
            top: 10,
            align: 'left',
            data: ['爆发期'],

            textStyle: {
              fontSize: 14,
              color: '#FF402B'
            },
            itemStyle: {
              color: ' rgba(255,64,43,0.3)',
              borderColor: '#FF402B',
              borderWidth: 1,
              borderType: 'solid'
            },
            backgroundColor: 'rgba(232,234,240,.5)',
            padding: [13, 16],
            borderRadius: 4
          },
          {
            orient: 'horizontal',
            left: 10,
            bottom: 10,
            align: 'left',
            data: ['消亡期'],
            textStyle: {
              fontSize: 14,
              color: '#8098BC'
            },
            itemStyle: {
              color: 'rgba(128,152,188,0.3)',
              borderColor: '#8098BC',
              borderWidth: 1,
              borderType: 'solid'
            },
            backgroundColor: 'rgba(232,234,240,.5)',
            padding: [13, 16],
            borderRadius: 4
          },
          {
            orient: 'horizontal',
            right: 10,
            bottom: 10,
            align: 'left',
            data: ['危害期'],
            textStyle: {
              fontSize: 14,
              color: '#FFC117'
            },
            itemStyle: {
              color: 'rgba(255,193,23,0.3)',
              borderColor: '#FFC117',
              borderWidth: 1,
              borderType: 'solid'
            },
            backgroundColor: 'rgba(232,234,240,.5)',
            padding: [13, 16],
            borderRadius: 4
          }
        ],
        xAxis: [
          {
            // type: 'value',
            name: '数量',
            scale: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            max: xMax,
            min: xMin
          }
        ],
        yAxis: [
          {
            // type: 'value',
            scale: true,
            name: '增长率',
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              show: false
            },
            max: yMax,
            min: yMin
          }
        ],
        series: [
          {
            name: '初始期',
            z: 3,
            type: 'scatter',
            symbolSize: (value: any, params: any) => {
              const name = this.getCurInsectName(params)

              if (name === this.currentInsectName) {
                return 40
              } else {
                return 30
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(24,144,255,0.3)',
                borderColor: '#1890FF',
                borderWidth: 1,
                borderType: 'solid'
              }
            },
            data: oneData
          },
          {
            name: '爆发期',
            z: 3,
            type: 'scatter',
            symbolSize: (value: any, params: any) => {
              const name = this.getCurInsectName(params)
              if (name === this.currentInsectName) {
                return 40
              } else {
                return 30
              }
            },
            itemStyle: {
              normal: {
                color: ' rgba(255,64,43,0.3)',
                borderColor: '#FF402B',
                borderWidth: 1,
                borderType: 'solid'
              }
            },
            data: twoData
          },

          {
            name: '消亡期',
            type: 'scatter',
            symbolSize: (value: any, params: any) => {
              const name = this.getCurInsectName(params)

              if (name === this.currentInsectName) {
                return 40
              } else {
                return 30
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(128,152,188,0.3)',
                borderColor: '#8098BC',
                borderWidth: 1,
                borderType: 'solid'
              }
            },
            data: threeData
          },
          {
            name: '危害期',
            type: 'scatter',
            symbolSize: (value: any, params: any) => {
              const name = this.getCurInsectName(params)

              if (name === this.currentInsectName) {
                return 40
              } else {
                return 30
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(255,193,23,0.3)',
                borderColor: '#FFC117',
                borderWidth: 1,
                borderType: 'solid'
              }
            },
            data: fourData
          },
          {
            name: '',
            type: 'scatter',
            data: [[xCenter, yCenter]],
            silent: true,
            symbol: 'none',

            markLine: {
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#cccccc'
                  }
                }
              },
              label: {
                show: true
              },
              symbol: 'none',
              data: [
                {
                  type: 'average',
                  name: 'x',
                  valueIndex: 0
                }
              ]
            }
          },
          {
            name: '',
            type: 'scatter',
            data: [[xCenter, yCenter]],
            silent: true,
            symbol: 'none',
            markLine: {
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#cccccc'
                  }
                }
              },
              symbol: 'none',
              data: [
                {
                  type: 'average',
                  name: 'y',
                  valueIndex: 1
                }
              ],
              label: {
                show: true
              }
            }
          }
        ]
      }

      this.scatterEcharts.on('click', (params: any) => {
        clearInterval(this.timer30Second)
        clearInterval(this.timer2Min)

        if (params.seriesName === '初始期') {
          this.getTempData(this.tempData1, params.dataIndex)
        } else if (params.seriesName === '爆发期') {
          this.getTempData(this.tempData2, params.dataIndex)
        } else if (params.seriesName === '消亡期') {
          this.getTempData(this.tempData3, params.dataIndex)
        } else if (params.seriesName === '危害期') {
          this.getTempData(this.tempData4, params.dataIndex)
        }
        this.currentInsectName = this.getCurInsectName(params)

        for (let i = 0; i < this.insectDataSortList.length; i++) {
          if (
            this.insectDataSortList[i].insectName === this.currentInsectName
          ) {
            this.insectDataIndex = i
          }
        }
        this.uploadLineEchart()
      })
      this.scatterEcharts.setOption(option)
    }
  }

  getInsectName (data: any, index: number) {
    return data[index].data.insectName
  }

  getTempData (data: any, index: number) {
    this.lineChartDate = data[index].data.dateList
    this.lineChartPercent = data[index].data.insectIncreaseList
    this.lineChartNumber = data[index].data.insectNumberList
  }

  // 折线
  getEchartsLine () {
    this.lineEcharts = Echarts.init(this.$refs.lineEcharts as any)

    const option = {
      backgroundColor: '#F6F7FB',
      grid: {
        top: '20%',
        left: 20,
        right: 20,
        bottom: '3%',
        show: true,
        borderWidth: '0',
        containLabel: true
      },
      title: {
        text: this.currentInsectName,
        left: 'center',
        top: 5
      },
      tooltip: {
        trigger: 'axis',
        // axisPointer: {
        //   type: 'cross',
        //   crossStyle: {
        //     color: '#999'
        //   }
        // },
        formatter: (params: any) => {
          if (params.length > 1) {
            const num = mathMul(params[1].value.toString(), 100)
            return `${params[0].name}:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>数量:${params[0].value}<br/>增长率:${num}%`
          }
          return false
        }
      },

      legend: [
        {
          left: 0,
          top: 5,
          align: 'left',
          z: 2,
          icon: 'rect',
          data: ['数量'],
          textStyle: {
            fontSize: 12,
            color: '#8C8C8C'
          },
          itemWidth: 16,
          itemHeight: 2,
          itemStyle: {
            backgroundColor: '#5B8FF9'
          }
        },
        {
          left: 70,
          top: 5,
          align: 'left',
          z: 2,
          icon: 'rect',
          data: ['增长率'],
          textStyle: {
            fontSize: 12,
            color: '#8C8C8C'
          },
          itemWidth: 16,
          itemHeight: 2,
          itemStyle: {
            backgroundColor: '#36CBCB'
          }
        }
      ],

      xAxis: [
        {
          type: 'category',
          data: this.lineChartDate,
          axisPointer: {
            type: 'shadow'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          min: 0,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        {
          type: 'value',
          name: '',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],

      series: [
        {
          name: '数量',
          type: 'bar',
          itemStyle: {
            normal: {
              color: '#5B8FF9'
            }
          },

          data: this.lineChartNumber
        },

        {
          name: '增长率',
          type: 'line',
          smooth: true,
          yAxisIndex: 1,
          itemStyle: {
            normal: {
              color: '#36CBCB'
            }
          },
          data: this.lineChartPercent
        }
      ]
    }
    this.lineEcharts.setOption(option)

    if (this.isFirst) {
      this.interval2Min()
      this.isFirst = false
    }
  }

  // 饼图
  getEchartsPie () {
    const nameList: Array<any> = []
    const numList: Array<any> = []
    const newData: Array<any> = this.arrSort(this.pieChartData)

    let totalInsect = 0
    if (this.pieChartData && this.pieChartData.length > 0) {
      this.pieChartData.forEach((item) => {
        totalInsect = mathAdd(totalInsect, item.insectNum)
      })
    }
    const pirCenteNumber =
      totalInsect > 9999 ? (totalInsect / 10000).toFixed(1) + '万' : totalInsect
    if (newData.length) {
      newData.forEach((item: any) => {
        nameList.push(item.name)
        numList.push(item.value)
      })
    }

    this.pieEcharts = Echarts.init(this.$refs.pieEcharts as any)
    const option = {
      tooltip: {
        show: true,
        // trigger: 'item'
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: [
        {
          orient: 'vertical',
          right: 2,
          top: 'center',
          icon: 'circle',
          itemWidth: 8,
          itemHeight: 8,
          tooltip: {
            show: true
            // trigger: 'item'
            // formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          formatter: (name: any) => {
            const data = newData
            let total = 0
            let target = 0
            for (let i = 0, l = data.length; i < l; i++) {
              total = mathAdd(total, Number(data[i].value))
              if (data[i].name === name) {
                target = Number(data[i].value)
              }
            }
            const nameName =
              name.length > 8 ? name.substring(0, 8) + '...' : name
            const arr = [
              '{a|' + nameName + '} | {b|' + mathMul(target / total, 100) + '%}'
            ]
            return arr
          },
          textStyle: {
            color: '#666666',
            rich: {
              a: {
                color: '#666666',
                fontSize: 14,
                align: 'center',
                padding: [0, 4, 0, 0]
              },
              b: {
                color: '#999999',
                fontSize: 12,
                align: 'center',
                padding: [0, 0, 0, 4]
              }
            }
          },

          data: nameList
        }
      ],

      series: [
        {
          name: '虫害合计',
          left: '2%',
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['30%', '50%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: true,
              position: 'center',
              color: '#4c4a4a',
              formatter:
                '{title|总虫害合计}' +
                '\n\n\r' +
                '{number|' +
                pirCenteNumber +
                '}',
              rich: {
                title: {
                  fontSize: 14,
                  color: '#999999',
                  fontFamily: 'PingFangSC-Regular, PingFang SC'
                },
                number: {
                  fontSize: 28,
                  color: '#000000',
                  fontFamily: 'PingFangSC-Regular, PingFang SC'
                }
              }
            },
            emphasis: {
              // 中间文字显示
              show: true
            }
          },
          emphasis: {
            label: {
              text: '总虫害合计',
              color: '#999999',
              show: true,
              fontSize: '14'
            }
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              color: (value: any) => {
                return this.pieChartColor[value.dataIndex]
              }
            }
          },

          data: newData
        }
      ]
    }
    this.pieEcharts.setOption(option)
  }

  // 饼图排序
  arrSort (arr: Array<any>) {
    arr.sort(function (arr1: any, arr2: any) {
      return arr2.insectNum - arr1.insectNum
    })
    const newArr: any = []
    if (arr.length <= 5) {
      arr.forEach(item => {
        newArr.push({ value: item.insectNum, name: item.insectName })
      })
    } else {
      const newS = arr.slice(0, 5)
      const newOthers = arr.slice(5)
      let remaTotal = 0
      newOthers.forEach(item => {
        remaTotal += Number(Number(item.insectNum).toFixed(2))
      })

      newS.push({ insectName: '其他', insectNum: remaTotal + '' })

      newS.forEach(item => {
        newArr.push({ value: item.insectNum, name: item.insectName })
      })
    }
    return newArr
  }

  // 四象限排序
  insectSort (arr: Array<any>) {
    arr.sort(function (arr1: any, arr2: any) {
      return arr2.insectNumbers - arr1.insectNumbers
    })
    return arr
  }

  destroyed () {
    window.removeEventListener('resize', this.changeResize)
    clearInterval(this.timer30Second)
    clearInterval(this.timer2Min)
    this.timer30Second = null
    this.timer2Min = null
  }
}
